<template>
    <v-autocomplete
        v-model="value"
        cache-items
        dense
        :small-chips="smallChips"
        :deletable-chips="deletableChips"
        :hide-selected="multiple"
        :items="itemsList"
        :search-input.sync="searchItem"
        open-on-clear
        item-value="IncCatId"
        item-text="IncCatNom"
        outlined
        no-data-text="Introduzca texto a buscar"
        :label="label"
        :readonly="readonly"
        :disabled="disabled"
        return-object
        ref="IncCatFindCtrl"
        :multiple="multiple"
        :clearable="!readonly"
        :menu-props="{'open-on-click': true}"
        @click="clickEvt"
        @click:append="clickAppendEvt"
        @input="inputEvt"
        @change="changeEvt"
        @blur="blurEvt"
    ></v-autocomplete>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'PerId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null
    }),
    props: [
      'value', 'label', 'incCatIdParent', 'showParentsOnly', 'showNoParentOnly', 
      'multiple', 'disabled', 'readonly', 'smallChips', 'deletableChips',
      'selectOnReadonly'
    ],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      value() {
        if (this.value != null) {
          this.loadItems();
        }
      },
      incCatIdParent() {
        this.$refs.IncCatFindCtrl.cachedItems = [];
        this.loadItems();
      },
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {   
            Action: 'GET_DATA_LIST_PAGINATED',
            IncCatNom: valFind,
            NumRegsPag: 35,
            NumPag: 1,
            EmpId: this.empId,
            ShowParentsOnly: this.showParentsOnly,
            ShowNoParentOnly: this.showNoParentOnly,
            IncCatIdParent: this.incCatIdParent,
            Searcher: true
        };       

        var curVal = this.value;

        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/inccat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
            this.itemsList = [];

            result.data.EntsList.forEach(element => {
                var itemObject = { IncCatId: element.IncCatId, IncCatNom: element.IncCatNom }
                this.itemsList.push(itemObject);
            });

            if(curVal != null) {
              // if (curVal.length == null) { 
              if (!Array.isArray(curVal)) {  // es un objeto
                if (curVal != null && curVal.IncCatId != null) {
                  if (!this.itemsList.some(itm=>itm.IncCatId === curVal.IncCatId)) {
                    this.itemsList.push(curVal);
                  }
                }           
              }
              else {
                curVal.forEach(element => {  // es un array
                    if (!this.itemsList.some(itm=>itm.IncCatId === element.IncCatId)) {
                      this.itemsList.push(element);
                    }
                });
              }
            }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.IncCatFindCtrl.isMenuActive) {
            this.$refs.IncCatFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.IncCatFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.IncCatFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems();
    },
  }
</script>

<template>
    <v-autocomplete
      v-model="value"
      cache-items
      dense
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      hide-selected
      :items="itemsList"
      :search-input.sync="searchItem"
      open-on-clear
      item-value="PerId"
      item-text="PerNom"
      outlined
      no-data-text="Introduzca texto a buscar"
      :label="label"
      :readonly="readonly"
      return-object
      ref="PerFindCtrl"
      :clearable="!readonly"
      :multiple="multiple"
      :menu-props="{'open-on-click': true}"
      @click="clickEvt"
      @click:append="clickAppendEvt"
      @input="inputEvt"
      @change="changeEvt"
      @blur="blurEvt"
      @update:search-input="updateSearchInputEvt"
    ></v-autocomplete>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null,
    }),
    props: [
      'value', 'perCatId', 'perCatIdAnyLevel', 'tipoFiltro', 'opeId', 'perIdRegistrado', 'isRrhh',
      'selectOnReadonly', 'label', 'multiple', 'readonly', 'smallChips', 'deletableChips'
    ],
    watch: {
      searchItem(val) {
       this.loadItems(val);
      },
      perCatId() {
        this.$refs.PerFindCtrl.cachedItems = [];
        this.loadItems();
      },
      perCatIdAnyLevel() {
        this.$refs.PerFindCtrl.cachedItems = [];
        this.loadItems();
      },
      opeId() {
        this.$refs.PerFindCtrl.cachedItems = [];
        this.loadItems();
      },
      value() {
        if (this.value != null && this.value != undefined && this.value != '') {
          this.loadItems();
        }
      }
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {   
            Action: 'GET_DATA_LIST_PAGINATED',
            PerNom: valFind,
            NumRegsPag: 35,
            NumPag: 1,
            EmpId: this.empId,
            PerCatId: this.perCatId,
            PerCatIdAnyLevel: this.perCatIdAnyLevel,
            OpeId: this.opeId,
            TipoFiltro: this.tipoFiltro,
            PerIdRegistrado: this.perIdRegistrado,
            IsRrhh: this.isRrhh,
            Searcher: true
        };       

            // Action: 'GET_DATA_LIST_PAGINATED',
            // CampoBusqueda: CampoBusqueda,
            // ValorBuscar: ValorBuscar,
            // NumRegsPag: NumRegsPag,
            // NumPag: NumPag,
            // EmpId: this.empId,
            // PerCatId: PerCatId,
            // PerIdRegistrado: this.$store.state.perId,
            // TipoFiltro: 'MENSAJES_PERMITIDOS'


      // this.itemsList = [];
      // if ((val == null || val == '') && (this.multiple == null || this.multiple != true)) {
      //   this.value = null;
      // }
      var valorActual = this.value;
      var AuthToken = localStorage.getItem('token'); 
       axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
       .then(result => {
          this.itemsList = [];
            result.data.EntsList.forEach(element => {
              var itemObject = {PerId: element.PerId, PerNom: element.PerNom}
              this.itemsList.push(itemObject);
            });

            if(valorActual != null) {
              // if (valorActual.length == null) {  
              if (!Array.isArray(valorActual)) {  // es un objeto
                if (valorActual.PerId != null) {
                  if (!this.itemsList.some(itm=>itm.PerId === valorActual.PerId)) {
                    this.itemsList.push(valorActual);
                  }
                }
              }
              else {
                valorActual.forEach(element => {  // es un array
                  if (!this.itemsList.some(itm=>itm.PerId === element.PerId)) {
                    this.itemsList.push(element);
                  }
                });
              }
            }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.PerFindCtrl.isMenuActive) {
            this.$refs.PerFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.PerFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.PerFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      },
      updateSearchInputEvt() {
        this.$emit('update:search-input', this.value)
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems();
    }
  }
</script>
<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Incidencias
            </div>

            <v-expansion-panels v-model="activePanels">
                <v-expansion-panel style="background-color: #F5F5F5" @click.prevent="toogleFilters">
                    <v-expansion-panel-header>
                        <span class="header">Filtros</span>
                        <template v-slot:actions>
                            <v-btn icon @click.stop="toogleFilters">
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form :disabled="loading">
                            <v-row>
                              <v-col cols="12" sm="5" md="3" lg="3" xl="2">
                                <v-text-field
                                  class="mb-n7"
                                  outlined
                                  dense
                                  number
                                  v-model="filtros.IncId"
                                  label="Identificador"
                                  @change="loadData">
                              </v-text-field>
                              </v-col>
                                <v-col class="mb-n7" cols="12" sm="5" md="3" lg="3" xl="2">
                                    <DateFind label="Desde" v-model="filtros.FechaDesde" @change="loadData"></DateFind>
                                </v-col>
                                <v-col class="mb-n7" cols="12" sm="5" md="3" lg="3" xl="2">
                                    <DateFind label="Hasta" v-model="filtros.FechaHasta" @change="loadData"></DateFind>
                                </v-col>
                                
                                <v-col cols="9" sm="9" md="5" lg="5" xl="6">
                                    <v-text-field
                                        class="mb-n7" outlined dense
                                        text
                                        v-model="filtros.IncDescrip"
                                        label="Descripción"
                                        @change="loadData">
                                    </v-text-field>
                                </v-col>
                                <v-col v-if="!esTienda" cols="12" sm="9" md="8" lg="6" xl="4">
                                    <DirFind class="mb-n7" ref="dirCtrl" v-model="filtros.Dir" label="Tienda" @change="loadData"></DirFind>
                                </v-col>
                                <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                                    <IncCatFind 
                                        class="mb-n7" 
                                        ref="incCatCtrl" 
                                        v-model="filtros.IncCatParent"
                                        :incCatIdParent="null"
                                        :showParentsOnly="true"
                                        label="Tipo"
                                        @change="onChangeIncCatParent">
                                    </IncCatFind>
                                </v-col>
                                <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                                    <IncCatFind 
                                        class="mb-n7" 
                                        ref="incCatCtrl" 
                                        v-model="filtros.IncCat"
                                        :incCatIdParent="filtros.IncCatParent == null ? null : filtros.IncCatParent.IncCatId"
                                        :readonly="filtros.IncCatParent == null"
                                        label="Categoría"
                                        @change="loadData">
                                    </IncCatFind>
                                </v-col>
                                <v-col cols="12" sm="5" md="3" lg="3" xl="2">
                                    <PriorFind class="mb-n7" ref="priorCtrl" v-model="filtros.Prior" label="Prioridad" @change="loadData"></PriorFind>
                                </v-col>
                                <v-col v-if="esDepartamento || esDirDep" cols="12" sm="8" md="6" lg="6" xl="4">
                                    <PerFind class="mb-n7" ref="perAsigFind" v-model="filtros.PerAsig" label="Asignada a" :perCatIdAnyLevel="101" @change="loadData"></PerFind>
                                </v-col>
                                <v-col v-if="esDepartamento || esDirDep" cols="12" sm="5" md="4" lg="3" xl="3">
                                    <v-select 
                                        dense outlined hide-details
                                        v-model="filtros.Asignada"
                                        :items="asignadaOptions"
                                        item-value="value"
                                        item-text="label"
                                        label="Asignadas"
                                        :menu-props="{ offsetY: true }"
                                        @change="loadData">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="5" md="3" lg="3" xl="2">
                                    <IncStaFind class="mb-n7" ref="incStaCtrl" v-model="filtros.IncSta" label="Estado" @change="loadData"></IncStaFind>
                                </v-col>
                                <v-col cols="12" sm="5" md="4" lg="3" xl="3">
                                    <v-select 
                                        dense outlined hide-details
                                        v-model="filtros.Finalizada"
                                        :items="finalizadaOptions"
                                        item-value="value"
                                        item-text="label"
                                        label="Finalizadas"
                                        :menu-props="{ offsetY: true }"
                                        @change="loadData">
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            
            <v-btn
                color="primary"
                class="mt-5 mb-3"
                @click="onClickNuevaIncidencia">
                Nueva
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarIncidencia"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.IncTmp="{ item }">
                    {{ formatDate(item.IncTmp) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.PerAsig.PerNom="{ item }">
                    {{ getPerNom(item.PerAsig) }}
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DateFind from "../../components/DateFind.vue";
import DirFind from "../../components/DirFind.vue";
import IncCatFind from "../../components/IncCatFind.vue";
import PerFind from "../../components/PerFind.vue";
import PriorFind from "../../components/PriorFind.vue";
import IncStaFind from "../../components/IncStaFind.vue";
export default ({
    components: {
        DateFind, DirFind, IncCatFind, PerFind, PriorFind, IncStaFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz', 'esTienda', 'esDepartamento', 'esDirDep'])
    },
    data () {
        return {
            loading: true,
            activePanels: 0,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [],
            headersBase: [
                { text: 'Identificador', width:100, sortable: false, value: 'IncId' },
                
                { text: 'Fecha', width:145, sortable: false, value: 'IncTmp' },
                { text: 'Descripción', width:250, sortable: false, value: 'IncDescrip' },
                { text: 'Tienda', width:225, sortable: false, value: 'Dir.DirNom' },
                { text: 'Categoría', width:200, sortable: false, value: 'IncCat.IncCatNom' },
                { text: 'Prioridad', width:100, sortable: false, value: 'Prior.PriorNom' },
                { text: 'Estado', width:100, sortable: false, value: 'IncSta.IncStaNom' }
            ],
            headersExtra: [
                { text: 'Asignada a', width:225, sortable: false, value: 'PerAsig.PerNom' }
            ],
            dtOptionsLoaded: false,
            filtros: {
                FechaDesde: null,
                FechaHasta: null,
                Dir: null,
                PerAsig: null,
                IncId: null,                
                IncDescrip: '',
                IncCat: null,
                IncCatParent: null,
                Prior: null,
                IncSta: null,
                Finalizada: false,
                Asignada: null
            },
            finalizadaOptions: [
              { label:'TODAS', value: null },
              { label:'FINALIZADAS', value: true },
              { label:'NO FINALIZADAS', value: false }
            ],
            asignadaOptions: [
              { label:'TODAS', value: null },
              { label:'ASIGNADAS', value: true },
              { label:'NO ASIGNADAS', value: false }
            ]
        }
    },
    watch: {
      options: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.dtOptionsLoaded) {
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      // filtros: {
      //   handler () {
      //     this.loadData();
      //   },
      //   deep: true
      // },
      loading: {
        handler() {
          if (this.loading) {
            if(!this.esTienda) { this.$refs.dirCtrl.$refs.DirFindCtrl.isMenuActive = false; }
            this.$refs.incCatCtrl.$refs.IncCatFindCtrl.isMenuActive = false;
            this.$refs.priorCtrl.$refs.PriorFindCtrl.isMenuActive = false;
            this.$refs.incStaCtrl.$refs.IncStaFindCtrl.isMenuActive = false;
          }
        },
        deep: true
      }
    },
    mounted() {
      if(this.esDepartamento || this.esDirDep)
      {
          this.headers = this.headersBase.concat(this.headersExtra);
      }
      else
      {
          this.headers = this.headersBase;
      }

      if (this.esTienda) {
        this.filtros.Dir = this.$store.state.dir;
        // Tienda oculta
        const indexDir = this.headers.findIndex(f => f.value == 'Dir.DirNom');
        if(indexDir != -1) { this.headers.splice(indexDir, 1); }
        // Categoria oculta
        const indexCat = this.headers.findIndex(f => f.value == 'IncCat.IncCatNom');
        if(indexCat != -1) { this.headers.splice(indexCat, 1); }
      }

      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            EmpId: this.empId,
            FechaDesde: this.filtros.FechaDesde,
            FechaHasta: this.filtros.FechaHasta,
            IncId: this.filtros.IncId != "" ? this.filtros.IncId : null,
            IncDescrip: this.filtros.IncDescrip,
            DirId: this.filtros.Dir != null ? this.filtros.Dir.DirId : null,
            IncCatIdParent: this.filtros.IncCatParent != null ? this.filtros.IncCatParent.IncCatId : null,
            IncCatId: this.filtros.IncCat != null ? this.filtros.IncCat.IncCatId : null,
            PriorId: this.filtros.Prior != null ? this.filtros.Prior.PriorId : null,
            IncStaId: this.filtros.IncSta != null ? this.filtros.IncSta.IncStaId : null,
            PerId: this.esTienda ? null : this.perId,
            PerIdAsig: this.filtros.PerAsig != null ? this.filtros.PerAsig.PerId : null,
            Finalizada: this.filtros.Finalizada,
            Asignada: this.filtros.Asignada
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false
                resolve({
                    items,
                    total
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar las incidencias.');
            }
          });
        })
      },
      loadData() {
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items
          this.totalDataRows = data.total
        });
      },
      onClickNuevaIncidencia() {
        this.$router.push('/incidencia-nuevo/');
      },
      onClickRowEditarIncidencia(item) {
        this.$router.push('/incidencia-editar/' + item.IncId);
      },
      onChangeIncCatParent()
      {
        this.filtros.IncCat = null;
        this.loadData();
      },
      getPerNom(per, defaultValue = '') {
        if (per == null || per.PerNom == null) { return defaultValue; }
        return per.PerNom;
      },
      toogleFilters() {
        this.activePanels = this.activePanels == null ? 0 : undefined;
      },
      formatDate (value) {
        if(value == null) { return ''; }
        const date = new Date(value);
        return String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear() + 
            ' ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');
      }
    } 
})
</script>

<template>
    <v-select
      v-model="value"
      cache-items
      dense
      outlined
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hide-selected="multiple"
      :items="itemsList"
      :search-input.sync="searchItem"
      open-on-clear
      item-value="PriorId"
      item-text="PriorNom"
      no-data-text="Elige una opción"
      :label="label"
      :readonly="readonly"
      return-object
      ref="PriorFindCtrl"
      :multiple="multiple"
      :clearable="!readonly"
      :menu-props="{ 'open-on-click': true, offsetY: true }"
      @click="clickEvt"
      @input="inputEvt"
      @change="changeEvt"
      @blur="blurEvt"
    ></v-select>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null
    }),
    props: ['value', 'label', 'multiple', 'readonly', 'smallChips', 'deletableChips'],
    watch: { },
    methods: {
      loadItems() {
        var controllerParameters = {
          Action: 'GET_DATA_LIST'
        };

        var AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/prior", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          this.itemsList = [];
          result.data.EntsList.forEach(element => {
            var itemObject = { PriorId: element.PriorId, PriorNom: element.PriorNom }
            this.itemsList.push(itemObject);
          });
        });
      },
      clickEvt() {
        this.$emit('click');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
        this.itemsList = [];
        this.loadItems();
    },
  }
</script>
<template>
    <v-autocomplete
        v-model="value"
        cache-items
        dense
        :small-chips="smallChips"
        :deletable-chips="deletableChips"
        hide-selected
        :items="itemsList"
        :search-input.sync="searchItem"
        open-on-clear
        item-value="DirId"
        item-text="DirNom"
        outlined
        no-data-text="Introduzca texto a buscar"
        :label="label"
        :readonly="readonly"
        return-object
        ref="DirFindCtrl"
        :clearable="!readonly"
        :multiple="multiple"
        :menu-props="{'open-on-click': true}"
        @click="clickEvt"
        @click:append="clickAppendEvt"
        @input="inputEvt"
        @change="changeEvt"
        @blur="blurEvt"
        @update:search-input="UpdateSearchInputEvt"
    ></v-autocomplete>
</template>

<script>
  import axios from "axios"
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null,
    }),
    props: ['value', 'tipoFiltro', 'label', 'multiple', 'readonly', 'smallChips', 'deletableChips', 'selectOnReadonly'],
    watch: {
      searchItem(val) {
       this.loadItems(val)
      },
      value() {
        if (this.value != null) {
          this.loadItems();
        }
      }
    },
    methods: {
      loadItems(valFind) {
        // if (valFind == null || valFind == '') {
        //   return;
        // }
        var controllerParameters = {   
            Action: "GET_DATA_LIST_PAGINATED",
            DirNom: valFind,
            NumRegsPag: 35,
            NumPag: 1,
            EmpId: this.empId,
            // TipoFiltro: this.tipoFiltro,
            Searcher: true
        };

            // Action: "GET_DATA_LIST_PAGINATED",
            // CampoBusqueda: CampoBusqueda,
            // ValorBuscar: ValorBuscar,
            // NumRegsPag: NumRegsPag,
            // NumPag: NumPag,
            // EmpId: this.empId,
            // DirCatId: DirCatId,
            // DirIdRegistrado: this.$store.state.dirId,
            // TipoFiltro: "MENSAJES_PERMITIDOS"


      // this.itemsList = [];
      // if ((val == null || val == "") && (this.multiple == null || this.multiple != true)) {
      //   this.value = null;
      // }

      var valorActual = this.value;
      var AuthToken = localStorage.getItem('token');  
       axios({ method: "POST", "url": this.urlRaiz + "/api/dir", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
       .then(result => {
          // this.itemsList = result.data.EntsList;
          this.itemsList = [];
            result.data.EntsList.forEach(element => {
              var itemObject = {DirId: element.DirId, DirNom: element.DirNom}
              this.itemsList.push(itemObject);
            });

            if(valorActual != null) {
              // if (valorActual.length == null) {  
              if (!Array.isArray(valorActual)) {  // es un objeto
                  if (valorActual.DirId != null) {
                    if (!this.itemsList.some(itm=>itm.DirId === valorActual.DirId)) {
                      this.itemsList.push(valorActual);
                    }
                  }
              }
              else {
                valorActual.forEach(element => {  // es un array
                  if (!this.itemsList.some(itm=>itm.DirId === element.DirId)) {
                    this.itemsList.push(element);
                  }
                });
              }
            }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.DirFindCtrl.isMenuActive) {
            this.$refs.DirFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.DirFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.DirFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      },
      UpdateSearchInputEvt() {
        this.$emit('update:search-input', this.value)
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems();
    }
  }
</script>